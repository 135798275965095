<template>
  <div class="system-rail">
    <el-input v-model="keyword" placeholder="基地名称/工地名称">
      <el-button slot="append">查询</el-button>
    </el-input>
    <div id="map"></div>
  </div>
</template>

<script>
import { mapCenter } from '@/config'

export default {
  name: 'system-rail',
  data () {
    return {
      keyword: ''
    }
  },
  mounted () {
    this.map = new window.AMap.Map('map', {
      center: mapCenter,
      zoom: 11
    })
  }
}
</script>

<style lang="scss">
.system-rail {
  padding: 30px;
  .el-input {
    width: 300px;
    margin-bottom: 20px;
  }
  #map {
    height: 80vh;
    min-height: 500px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
}
</style>
